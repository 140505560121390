import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { alternativeProducts, getBFY } from '../../APIs/functions';
import ProductCarousel from '../ProductCarousel';
import './index.scss'
import { NameContext } from '../../Context/NameContext';
import ClientProductCarousel from '../ClientProductCarousel'
import { Spin } from 'antd';

const Alternative = ({ product }) => {
  const url = new URL(window.location.href);
  const hostnameParts = url.hostname.split('.');
  const client = hostnameParts.length > 2 ? hostnameParts.slice(0, hostnameParts.length - 2).join('.') : '';
  // const [isRefferer, setIsRefferer] = useState(false);

  // useEffect(() => {
  //   const referringSite = document.referrer;
  //   if (referringSite.includes('https://marquette.storebyweb.com/')) {
  //     setIsRefferer(true);
  //   }
  // }, []);

  function getLocationIdParam(url) {
    let locationIdParam = url.searchParams.get('location_id');

    if (locationIdParam) {
      locationIdParam = locationIdParam.split('?')[0];
    }

    return locationIdParam;
  }

  const userLocation = getLocationIdParam(url);

  const { productNameURL, upcCode } = product
  const queries = {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    cacheTime: 325000,
    staleTime: 300000
  }

  const { name } = useContext(NameContext)

  const preferences = JSON.parse(localStorage.getItem('preferences')) || {};

  // const { data: alternateProducts, isLoading: alternateProductsLoading, isError: alternateProductsError } = useQuery(['alternateProducts', productNameURL, client, userLocation], () => alternativeProducts({ productNameURL, client, userLocation, preferences }), queries)

  const { data: bfyData, isLoading: bfyLoading, isError: bfyError } = useQuery(['BFYData', upcCode, client], () => getBFY({ upcCode, client, userLocation, preferences }), queries)

  // const products = (!alternateProductsLoading &&
  //   !alternateProductsError &&
  //   Array.isArray(alternateProducts) ? alternateProducts : alternateProducts?.productRecommendedData
  // );

  const products = Array.isArray(bfyData) ? bfyData : (bfyData?.productRecommendedData || []);

  return (
    <>
      {/* {bfyLoading || alternateProductsLoading ?
        <div className='flex align-items-center text-center justify-content-center my-5 mx-auto w-100'>
          <Spin />
        </div>
        : <Fragment>
          {isRefferer ?
            !bfyLoading && productWithImages.length > 0 && <section id="alternatives">
              <div className='container overflow-hidden'>
                <div className='text-center alternative-title'>
                  <h2><strong className="d-block"> Better-for-You Alternatives</strong>  <span> at </span > <span className="text-capitalize">{name}</span> </h2>
                </div >
                <ClientProductCarousel carousel={'alternative'} items={bfyData} />
              </div>
            </section>
            : !alternateProductsLoading && productWithImages.length > 0 && <section id="alternatives">
              <div className='container overflow-hidden'>
                <div className='text-center alternative-title'>
                  <h2><strong className="d-block"> Better-for-You Alternatives</strong>  <span> at </span > <span className="text-capitalize">{name}</span> </h2>
                </div >
                <ProductCarousel carousel={'alternative'} items={productWithImages} />
              </div>
            </section>}
        </Fragment>} */}
      {!bfyLoading && products?.length > 0 && <section id="alternatives">
        <div className='container overflow-hidden'>
          <div className='text-center alternative-title'>
            <h2><strong className="d-block"> Better-for-You Alternatives</strong>  <span> at </span > <span className="text-capitalize">{name}</span> </h2>
          </div >
          <ProductCarousel carousel={'alternative'} items={products} />
        </div>
      </section>}
    </>
  )
}

export default Alternative